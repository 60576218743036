import css from './GoogleReviewsRatingCount.module.scss';
import ReviewRating from '../ReviewRating/ReviewRating';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

const GoogleReviewsRatingCount = () => {
  const state = useSelector(state => state.ListingPage);
  const googleListingUrl = state?.googleReviews?.url;
  const averageRating = state?.googleReviews?.averageRating;
  const totalReviewCount = state?.googleReviews?.totalReviewCount;

  const openGoogleListing = () => {
    if (googleListingUrl) {
      window.open(googleListingUrl, '_blank');
    }
  };

  return averageRating ? (
    <div className={css.row}>
      <div className={css.col12}>
        <span>{averageRating} </span>
        <ReviewRating reviewStarClassName={css.googleReviewStar} rating={averageRating} />
        <br />
        {totalReviewCount && (
          <span className={css.totalReviewCount}>
            <a onClick={() => openGoogleListing()}>
              <FormattedMessage
                id="GoogleReviewsRatingCount.totalReviewCount"
                values={{ totalReviewCount }}
              />
            </a>
          </span>
        )}
      </div>
    </div>
  ) : null;
};

export default GoogleReviewsRatingCount;
